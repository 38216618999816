<template>
    <router-view :key="$route.fullPath"/>
</template>

<script>
import common from './common/api/common';
import util from './common/util';
import {v4 as uuidv4} from 'uuid';

import {mapMutations, mapGetters} from 'vuex';
import config from "@/common/api/config";
import store from "@/store/index"


export default {
    computed: {
        ...mapGetters(["isLogin", "userId", "contractSocket"])
    },
    mounted() {
        let uuid = util.getCache('deviceId', '');
        if (uuid === '') {
            uuid = uuidv4();
            util.setCache('deviceId', uuid);
        }
        this.getSiteConfig();
        this.getLocal();
        this.getServerTime();
        this.autoLogin();

        setTimeout(() => {
            const them = this.$util.getCache('userTheme', 'dark');
            switch (them) {
                case 'light':
                    this.$store.commit('updateTheme', 'light');
                    break;
                case 'dark':
                    this.$store.commit('updateTheme', 'dark');
                    break;
            }
        }, 500);
    },
    methods: {
        ...mapMutations(['setConfig', 'userLogin']),
        autoLogin() {
            common.userinfo().then((res) => {
                if (res.code === 200) {
                    this.userLogin(res.data);
                } else {
                    this.userLogin(false);
                    this.$util.delCache('token');
                }
            });
        },
        getServerTime() {
            common.getServerTime().then(res => {
                this.$util.setCache('time_zone', res.data.zone);
            })
        },
        getSiteConfig() {
            common.config(1).then((res) => {
                this.setConfig(res.data);
            });
        },
        getLocal() {
            const lmap = {
                CN: 'zh',
                TW: 'zh-Hant',
                HK: 'zh-Hant',
                MO: 'zh-Hant',
                JP: 'jp',
                KR: 'kr'
            };

            //to 文件配置格式化封装
            const cny = {
                text: this.$t('setup.cny'),
                value: 'CNY',
                symbol: '¥'
            };
            const jpy = {
                text: this.$t('setup.jpy'),
                value: 'JPY',
                symbol: '¥'
            };
            const usd = {
                text: this.$t('setup.usd'),
                value: 'USD',
                symbol: '$'
            };
            const cmap = {
                CN: cny,
                TW: cny,
                HK: cny,
                MO: cny,
                JP: jpy
            };
            let _this = this;
            common.getRequestInfo().then((res) => {
                if (res.code === 200) {
                    const lang = typeof lmap[res.data.country] != 'undefined' ? lmap[res.data.country] : 'en';
                    const currency = typeof cmap[res.data.country] != 'undefined' ? cmap[res.data.country] : usd;
                    util.setCache('local-ip', res.data.ip);
                    util.setCache('local-country', res.data.country);
                    try {
                        const _currency = util.getCache('local-currency');
                        if (typeof _currency == 'undefined' || _currency === '') {
                            _this.$store.commit('updateSymbol', parseFloat(currency.symbol));
                            util.setCache('local-currency', JSON.stringify(currency));
                            common.getCurrencyFee(currency.value).then((res) => {
                                if (res.code === 200) {
                                    util.setCache('local-fee', res.data.tether[currency.value.toLowerCase()]);
                                    _this.$store.commit('updateSRate', parseFloat(res.data.tether[currency.value.toLowerCase()]));
                                }
                            });
                        }
                    } catch (e) {
                    }
                    try {
                        const _lang = util.getCache('lang');
                        console.log(_lang);
                        if (typeof _lang == 'undefined' || _lang === '') {
                            this.$i18n.locale = lang;
                            util.setCache('lang', lang);
                        }
                    } catch (e) {
                    }
                }
            });
        },
    }
}
</script>
<style lang="scss">
#app {
    font-family: "Switzer-Bold";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffffff;
}
</style>
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    { path: "/", name: "HomeView", component: () => import("../views/newHome/newHome.vue") },
    { path: "/BindBox", name: "BindBox", component: () => import("../views/BindBox/BindBox.vue") },
    { path: "/login", name: "login", component: () => import("../views/Login/login.vue") },
    { path: "/register", name: "register", component: () => import("../views/Register/register.vue") },
    { path: "/forgotPassword", name: "forgotPassword", component: ()=>import("../views/forgotPassword/forgotPassword.vue") },
    { path: "/resetPass", name: "resetPass", component: () => import("../views/ResetPass/resetPass.vue") },
    { path: "/onePurchase", name: "onePurchase", component: () => import("../views/onePurchase/onePurchase.vue") },
    { path: "/flashOption", name: "flashOption", component: () => import("../views/Flash/flashOption.vue") },
    { path: "/volatilityOption", name: "volatilityOption", component: () => import("../views/Volatility/volatilityOption.vue") },
    { path: "/term", name: "term", component: () => import("../views/Home/investment/Term/term.vue") },
    { path: "/spot", name: "spot", component: () => import("../views/Home/spot/spot.vue") },
    { path: "/futures", name: "futures", component: () => import("../views/Home/futures/futures.vue") },
    { path: "/mock", name: "mock", component: () => import("../views/Home/mock/mock.vue") },
    { path: "/HomeView/futures/futuresData", name: "futuresData", component: () => import("../views/Home/futures/futuresData/futuresData.vue") },
    { path: "/copyTraders", name: "copyTraders", component: () => import("../views/Home/copyTraders/copyTraders.vue") },
    { path: "/investment", name: "investment", component: () => import("../views/Home/investment/investment.vue") },
    { path: "/fund", name: "fund", component: () => import("../views/Home/investment/fund/fund.vue") },
    { path: "/HomeView/investment/fund/fundDetails", name: "fundDetails", component: () => import("../views/Home/investment/fund/fundDetails/fundDetails.vue") },
    { path: "/HomeView/investment/Term/detail", name: "termDetail", component: () => import("../views/Home/investment/Term/detail/detail.vue") },
    { path: "/easyEarn", name: "easyEarn", component: () => import("../views/Home/investment/easyEarn/easyEarn.vue") },
    { path: "/pledge", name: "pledge", component: () => import("../views/Home/investment/pledge/pledge.vue") },
    { path: "/HomeView/assets", name: "assets", component: () => import("../views/Home/assets/assets.vue") },
    { path: "/HomeView/order", name: "order", component: () => import("../views/Home/order/orderView.vue") },
    { path: "/HomeView/personal/:e?", name: "personal", component: () => import("../views/Home/personal/userManagement.vue") },
    { path: "/help", name: "help", component: () => import("../views/Help/help.vue") },
    { path: "/categories/", name: "categories", component: () => import("../views/Help/categories/categories.vue") },
    { path: "/rank", name: "rank", component: () => import("../views/rank/rank.vue") },
    { path: "/topup", name: "topUp", component: () => import("../views/Home/assets/topUp/topUp.vue") },
    { path: "/withdraw", name: "withdraw", component: () => import("../views/Home/assets/withdraw/withdraw.vue") },
    { path: "/quotes", name: "quotes", component: () => import("../views/Home/quotes/quotes.vue") },
    { path: "/about", name: "about",  component: () => import("../views/about/about.vue")},
    { path: "/download", name: "download", component: () => import("../views/Home/appDownload/appDownload.vue") },
    { path: "/ratestandards", name: "ratestandards", component: () => import("../views/rateStandards/rateStandards.vue")},
    { path: "/messages", name: "messages", component: () => import("../views/messageBoard/messageBoard.vue") },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {top: 0}
    }
});

export default router;
